<template>
  <Layout class="main-container">
    <!--    search-->
    <Form
      ref="SearchForm"
      inline
      class="search-form-container"
      :modal="params"
      label-position="left"
    >
      <FormItem>
        <Input placeholder="商品编号" v-model="params.productCode" />
      </FormItem>
      <FormItem>
        <Input placeholder="商品名称" v-model="params.productName" />
      </FormItem>
      <FormItem prop="Type">
        <Select clearable v-model="params.shipperType" placeholder="物流公司">
          <Option
            v-for="item in ShipperTypeEnum"
            :value="item.ID"
            :key="item.ID"
          >
            {{ item.CN }}
          </Option>
        </Select>
      </FormItem>
      <FormItem prop="Type">
        <Select
          multiple
          clearable
          v-model="orderTypeArr"
          placeholder="订单类型"
        >
          <Option v-for="item in OrderTypeEnum" :value="item.ID" :key="item.ID">
            {{ item.CN }}
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          placeholder="选择开始时间"
          type="datetime"
          v-model="params.begindate"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <DatePicker
          placeholder="选择结束时间"
          type="datetime"
          v-model="params.enddate"
        ></DatePicker>
      </FormItem>
      <FormItem style="text-align: left">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          @click="handleSearch"
        >
          搜索
        </Button>
        <Button class="ml2" size="small" @click="handleReset"> 重置 </Button>
        <Button
          type="success"
          class="ml2"
          size="small"
          icon="md-cloud-download"
          @click="ExportInfo"
        >
          导出
        </Button>
      </FormItem>
    </Form>
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['ShippedProduct_Search'])"
          @click="handleOpenDetail(row)"
        >
          查看订单
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <shipped-order-detail ref="ShippedOrderDetail" />
    <detail-drawer ref="DetailDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import Layout from "@/components/layout/index.vue";
import bizEnum from "@/libs/bizEnum";
import moment from "moment";
import ShippedOrderDetail from "./components/shipped-order-detail.vue";
import DetailDrawer from "./components/detail-drawer.vue";
export default {
  name: "ShippedOrderList",
  data() {
    return {
      orderTypeArr: [],
      tableLoading: false,
      tableHeight: 0,
      columns: [
        { title: "商品ID", key: "ProductID", sortable: "custom", minWidth: 70 },
        {
          title: "商品编号",
          key: "ProductCode",
          sortable: "custom",
          minWidth: 90,
        },
        {
          title: "商品名称",
          key: "ProductName",
          sortable: "custom",
          minWidth: 200,
          ellipsis: true,
        },
        { title: "商品分类", key: "ProductClassName", minWidth: 90 },
        { title: "商品规格", key: "ProductModel", minWidth: 90 },
        { title: "商品单位", key: "Unit", minWidth: 90 },
        {
          title: "数量",
          key: "ProductCount",
          minWidth: 60,
          sortable: "custom",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      params: {
        begindate: `${moment().format("YYYY-MM-DD")} 00:00:00`,
        enddate: `${moment().format("YYYY-MM-DD")} 23:59:59`,
        orderType: "",
        productCode: "",
        productName: "",
        shipperType: "",
        exportName: "已发货商品",
        Page: 1,
        PageSize: 40,
        SortName: "ProductID",
        SortOrder: "Desc",
      },
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        width: this.$route.meta.tableActionWidth + 24,
        fixed: "right",
        align: "center",
        slot: "action",
      });
    }
    this.loadData();
  },
  computed: {
    ShipperTypeEnum() {
      return bizEnum.ShipperTypeEnum;
    },
    OrderTypeEnum() {
      return bizEnum.OrderTypeEnum;
    },
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.addEventListener("keydown", this.keydownFun);
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadData();
      }
    });
  },
  activated() {
    document.addEventListener("keydown", this.keydownFun);
  },
  deactivated() {
    document.removeEventListener("keydown", this.keydownFun);
  },
  methods: {
    keydownFun() {
      const self = this;
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadData();
        if (self.$refs.ShippedOrderDetail.isShow) {
          self.$refs.ShippedOrderDetail.loadData();
        }
      }
    },
    ExportInfo() {
      let filename = `已发货商品 ${moment(this.params.begindate).format(
        "YYYYMMDD"
      )}-${moment(this.params.enddate).format("YYYYMMDD")}.xls`;
      this.params.exportName = filename;
      api.ExportShippedProductExcel(this.params);
    },
    handleOpenDetail(row) {
      this.$refs.ShippedOrderDetail.params.productid = row.ProductID;
      this.$refs.ShippedOrderDetail.params.begindate = this.params.begindate;
      this.$refs.ShippedOrderDetail.params.enddate = this.params.enddate;
      this.$refs.ShippedOrderDetail.params.shipperType =
        this.params.shipperType;
      this.$refs.ShippedOrderDetail.params.orderType =
        this.orderTypeArr.toString();
      // this.$refs.ShippedOrderDetail.params.orderType = this.params.orderType;
      this.$refs.ShippedOrderDetail.isShow = true;
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadData();
    },
    handleReset() {
      this.params.begindate = `${moment().format("YYYY-MM-DD")} 00:00:00`;
      this.params.enddate = `${moment().format("YYYY-MM-DD")} 23:59:59`;
      this.params.productCode = "";
      this.params.productName = "";
      this.orderTypeArr = [];
      this.loadData();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadData();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadData();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadData();
    },
    async loadData() {
      this.tableLoading = true;
      try {
        this.params.orderType = this.orderTypeArr.toString();
        const res = await api.GetShippedOrderProductList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.tableLoading = false;
      }
    },
    // 选中行样式
    RowClassName() {
      return "";
    },
  },
  components: { ShippedOrderDetail, DetailDrawer, Layout },
};
</script>

<style lang="less">
.ivu-table .demo-table-error-row td {
  background-color: #ffd591 !important;
  color: rgb(0, 0, 0);
}
</style>
